import axios from 'axios';

// src/services/paymentService.ts
import config from '../config/config';
import { axiosBase } from './axiosConfig';

const { SERVER_BASE_URL, SERVER_API_STRIPE } = config;

const createPaymentIntent = async (amount: number, email: string) => {
    try {
        const response = await axiosBase.post(`${SERVER_BASE_URL}${SERVER_API_STRIPE}create-payment-intent`, {
            amount,
            email,
        });

        return { clientSecret: response.data.clientSecret, error: null };
    } catch (error: unknown) {
        console.error('Error in createPaymentIntent:', error);

        if (axios.isAxiosError(error)) {
            // Axios specific error
            if (error.response) {
                console.error('Server responded with:', error.response.data);
                return { clientSecret: null, error: error.response.data };
            } else {
                return { clientSecret: null, error: 'An unknown error occurred.' };
            }
        } else {
            // Generic or unexpected error
            return { clientSecret: null, error: 'An unexpected error occurred.' };
        }
    }
};

export default {
    createPaymentIntent
};