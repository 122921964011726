import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import PaymentForm from './PaymentForm';

const CheckoutPage = () => {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialAmount = Number(queryParams.get('price')) || 49; // Default to 10 if no price is passed
  const [amount, setAmount] = useState(initialAmount);
  const [statusMessage, setStatusMessage] = useState<string | null>(null);

  const handlePaymentSuccess = () => {
    setStatusMessage("Payment successful!");
  };

  const handlePaymentError = (errorMessage: string) => {
    setStatusMessage(`Payment error: ${errorMessage}`);
  };

  return (
    <div>
      <section className="py-4 bg-white dark:bg-gray-900 lg:pt-12 lg:pb-16">
        <div className="px-4 mx-auto max-w-8xl lg:px-4 lg:text-center">
          <h1 className="mb-16 text-4xl font-bold tracking-tight text-gray-900 lg:font-extrabold lg:text-6xl lg:leading-none dark:text-white lg:text-center xl:px-36 lg:mb-7">
            Checkout
          </h1>
          <div className="mb-8">
            <label htmlFor="amountInput" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Amount (in dollars):
            </label>
            <input
              id="amountInput"
              type="number"
              value={amount}
              onChange={(e) => setAmount(Number(e.target.value))}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          {statusMessage && (
            <div className={`mb-4 p-4 rounded-md ${statusMessage.startsWith('Payment error') ? 'bg-red-100 text-red-800' : 'bg-green-100 text-green-800'}`}>
              {statusMessage}
            </div>
          )}
          <PaymentForm
            amount={amount}
            onSuccessfulPayment={handlePaymentSuccess}
            onPaymentError={handlePaymentError}
          />
        </div>
      </section>
    </div>
  );
};

export default CheckoutPage;