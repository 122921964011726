// src/services/subscriptionTierService.ts
import { axiosWithInterceptor } from './axiosConfig';
import config from '../config/config';
import axios from 'axios';

export interface SubscriptionTierDTO {
    id: string;
    name: string;
    pricePerMonth: number;
    pricePerYear: number;
    lastEditedAt: string;
}

export interface UpdateSubscriptionTierDTO {
    idToUpdate: string;
    name: string;
    pricePerMonth: number;
    pricePerYear: number;
}

//write an interface createAspRoleDTO with the following properties: name and description
export interface CreateSubscriptionTierDTO {
    name: string;
    pricePerMonth: number;
    pricePerYear: number;
}

export interface DeleteSubscriptionTierDTO {
    id: string;
}


const { SERVER_BASE_URL, SERVER_API_SUBSCRIPTIONTIERS } = config;

class SubscriptionTierService {
        static async getSubscriptionTiers(): Promise<SubscriptionTierDTO[]> {    
            try {
                const response = await axiosWithInterceptor.get(`${SERVER_BASE_URL}${SERVER_API_SUBSCRIPTIONTIERS}`);
                if (response.status === 200) {
                    return response.data;
                } else {
                    throw new Error('Unexpected response status');
                }
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    if (error.response) {
                        // Handle unauthorized error
                        if (error.response.status === 401 || error.response.status === 403) {
                            throw new Error('You are not authorized to perform this action.');
                        }
                        // Handle bad request
                        if (error.response.status === 400) {
                            throw new Error(error.response.data || 'Bad request');
                        }
                    }
                    throw new Error(error.message || 'Network error');
                }
                throw error; // Throw other types of errors (non-Axios errors)
            }
        }

        static async deleteSubscriptionTier(id: string): Promise<any> {
            try {
                const response = await axiosWithInterceptor.delete(`${SERVER_BASE_URL}${SERVER_API_SUBSCRIPTIONTIERS}/${id}`);
                if (response.status === 200) {
                    return response.data;
                } else {
                    throw new Error('Unexpected response status');
                }
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    if (error.response) {
                        // Handle unauthorized error
                        if (error.response.status === 401 || error.response.status === 403) {
                            throw new Error('You are not authorized to perform this action.');
                        }
                        // Handle bad request
                        if (error.response.status === 400) {
                            throw new Error(error.response.data || 'Bad request');
                        }
                    }
                    throw new Error(error.message || 'Network error');
                }
                throw error; // Throw other types of errors (non-Axios errors)
            }
        }

        static async updateSubscriptionTier (subscriptionTierData: UpdateSubscriptionTierDTO) {
        try {
            const response = await axiosWithInterceptor.put(`${SERVER_BASE_URL}${SERVER_API_SUBSCRIPTIONTIERS}`, subscriptionTierData);

            if (response.status === 200) {
                return response.data;
            } else {
                throw new Error('Unexpected response status');
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    // Handle unauthorized error
                    if (error.response.status === 401 || error.response.status === 403) {
                        throw new Error('You are not authorized to perform this action.');
                    }
                    // Handle bad request
                    if (error.response.status === 400) {
                        throw new Error(error.response.data || 'Bad request');
                    }
                }
                throw new Error(error.message || 'Network error');
            }
            throw error;
        }
    };

}
export default SubscriptionTierService;
