// src/components/navigation/Footer.tsx
import React from 'react';
import { Link } from 'react-router-dom';
//import { useVersionInfo } from '../../contexts/VersionInfoContext';
import { RoutePaths } from '../../routes/RoutePaths';
//import { Spinner } from 'flowbite-react';

const VersionDisplay: React.FC = () => {
    //  const versionInfo = useVersionInfo();

    const Footer: React.FC = () => {
        return (
            <footer className="justify-self-end pt-16 pb-8 lg:pt-24 lg:pb-10 bg-white dark:bg-gray-900">
                <div className="px-4 mx-auto max-w-8xl lg:px-4">
                    <div className="grid gap-12 lg:grid-cols-6 lg:gap-18">
                        <div className="col-span-2">
                            <Link className="flex mb-6" to={RoutePaths.Home}>
                                <span className="self-center ml-3 text-2xl font-semibold text-gray-900 dark:text-white">Patalytica
                                </span>
                            </Link>
                            <p className="text-gray-600 dark:text-gray-400">Patalytica is a patent analytics tool using live public data from global patent offices.
                            </p>
                        </div>
                        <div>
                            <h3 className="mb-6 text-sm font-semibold text-gray-400 uppercase dark:text-white">Resources
                            </h3>
                            <ul>
                                <li className="mb-4">
                                    <Link to="/" className="font-medium text-gray-600 dark:text-gray-400 dark:hover:text-white hover:underline">Documentation
                                    </Link>
                                </li>
                                {/* <li className="mb-4">
                                    <Link className="font-medium text-gray-600 dark:text-gray-400 dark:hover:text-white hover:underline" to="/blocks/">Patalytica Blocks
                                    </Link>
                                </li>
                                <li className="mb-4">
                                    <Link className="font-medium text-gray-600 dark:text-gray-400 dark:hover:text-white hover:underline" to="/icons/">Patalytica Icons
                                    </Link>
                                </li>
                                <li className="mb-4">
                                    <Link className="font-medium text-gray-600 dark:text-gray-400 dark:hover:text-white hover:underline" to="/figma/">Patalytica Figma
                                    </Link>
                                </li>
                                <li className="mb-4">
                                    <Link className="font-medium text-gray-600 dark:text-gray-400 dark:hover:text-white hover:underline" to="https://chat.openai.com/g/g-y7yC35HB9-flowbite-gpt">Patalytica GPT
                                    </Link>
                                </li>
                                <li className="mb-4">
                                    <Link className="font-medium text-gray-600 dark:text-gray-400 dark:hover:text-white hover:underline" to="/pro/">Pro version
                                    </Link>
                                </li> */}
                            </ul>
                        </div>
                        <div>
                            <h3 className="mb-6 text-sm font-semibold text-gray-400 uppercase dark:text-white">
                                Help & support
                            </h3>
                            <ul>
                                <li className="mb-4">
                                    <Link className="font-medium text-gray-600 dark:text-gray-400 dark:hover:text-white hover:underline" to="/contact/">Contact us
                                    </Link>
                                </li>
                                {/* <li className="mb-4">
                                    <Link className="font-medium text-gray-600 dark:text-gray-400 dark:hover:text-white hover:underline" to="/dashboard/support/">Support center
                                    </Link>
                                </li>
                                <li className="mb-4 flex items-center">
                                    <Link className="font-medium text-gray-600 dark:text-gray-400 dark:hover:text-white hover:underline" to="/work-with-us/">Work with us
                                    </Link>
                                    <span className="bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300 ml-2">New
                                    </span>
                                </li> */}
                            </ul>
                        </div>
                        <div>
                            <h3 className="mb-6 text-sm font-semibold text-gray-400 uppercase dark:text-white">Follow us
                            </h3>
                            {/* <ul>
                                <li className="mb-4">
                                    <Link to="https://discord.gg/4eeurUVvTy" rel="noreferrer nofollow" className="font-medium text-gray-600 dark:text-gray-400 dark:hover:text-white hover:underline">Discord
                                    </Link>
                                </li>
                                <li className="mb-4">
                                    <Link to="https://github.com/themesberg" rel="noreferrer nofollow" className="font-medium text-gray-600 dark:text-gray-400 dark:hover:text-white hover:underline">Github
                                    </Link>
                                </li>
                                <li className="mb-4">
                                    <Link to="https://twitter.com/zoltanszogyenyi" rel="noreferrer nofollow" className="font-medium text-gray-600 dark:text-gray-400 dark:hover:text-white hover:underline">Twitter
                                    </Link>
                                </li>
                            </ul> */}
                        </div>
                        <div>
                            <h3 className="mb-6 text-sm font-semibold text-gray-400 uppercase dark:text-white">Legal
                            </h3>
                            {/* <ul>
                                <li className="mb-4">
                                    <Link className="font-medium text-gray-600 dark:text-gray-400 dark:hover:text-white hover:underline" to="/license/">License (EULA)
                                    </Link>
                                </li>
                                <li className="mb-4">
                                    <Link className="font-medium text-gray-600 dark:text-gray-400 dark:hover:text-white hover:underline" to="/privacy-policy/">Privacy policy
                                    </Link>
                                </li>
                                <li className="mb-4">
                                    <Link className="font-medium text-gray-600 dark:text-gray-400 dark:hover:text-white hover:underline" to="/terms-and-conditions/">Terms &amp; conditions
                                    </Link>
                                </li>
                                <li className="mb-4">
                                    <Link className="font-medium text-gray-600 dark:text-gray-400 dark:hover:text-white hover:underline" to="/brand/">Brand guideline
                                    </Link>
                                </li>
                            </ul> */}
                        </div>
                    </div>
                    <hr className="my-8 border-gray-200 dark:border-gray-700 lg:my-12" />
                    <span className="block font-normal text-center text-gray-600 dark:text-gray-400">2019-2024 Patalytica
                        <Link className="ml-1 text-purple-700 hover:underline dark:text-purple-500" to={RoutePaths.ChangeLog}>
                            {/* V{versionInfo && versionInfo.version ||
                                <Spinner size='sm' color='purple' />
                            } */}
                            V1.0.2
                        </Link>. Patalytica is filed as trademark. All Rights Reserved.
                    </span>
                </div>
            </footer>
        );
    };

    return <Footer />;
};

export default VersionDisplay;