// src/components/dashboard/billing/Billing.tsx
import React from 'react';
//import { Checkbox, Label, TextInput, Button } from 'flowbite-react';


const Billing: React.FC = () => {   

    return (
        <div className="px-4 my-10 mx-auto max-w-8xl lg:px-4">
            <div className="mb-8">
                <h1 className="inline-block text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                    Billing settings
                </h1>
                <p className="mt-1 text-lg text-gray-500 dark:text-gray-400">
                    View and update details for your account.
                </p>
            </div>
        </div>

    );
};

export default Billing;