import React, { useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import paymentService from '../services/paymentService';

interface PaymentFormProps {
  amount: number; // Amount in dollars
  onSuccessfulPayment: () => void;
  onPaymentError: (errorMessage: string) => void;
}

const PaymentForm: React.FC<PaymentFormProps> = ({ amount, onSuccessfulPayment, onPaymentError }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('card'); // Default to card
  const [email, setEmail] = useState(''); // State for storing email address

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!stripe || !elements) {
      onPaymentError('Stripe has not loaded yet.');
      return;
    }

    setIsLoading(true);

    try {
      const cardElement = elements.getElement(CardElement);

      if (!cardElement) {
        onPaymentError('Card details not entered correctly.');
        setIsLoading(false);
        return;
      }

      // Convert amount to cents
      const amountInCents = Math.round(amount * 100);

      // Send email and amount to the backend to create the payment intent
      const { clientSecret, error } = await paymentService.createPaymentIntent(amountInCents, email);

      if (error) {
        onPaymentError(error);
        setIsLoading(false);
        return;
      }

      if (!clientSecret) {
        onPaymentError('Failed to retrieve payment information.');
        setIsLoading(false);
        return;
      }

      // Use the clientSecret in stripe.confirmCardPayment
      const paymentResult = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
        },
      });
      if (paymentResult.error) {
        const errorMessage = paymentResult.error.message || 'An error occurred with your payment.';
        onPaymentError(errorMessage);
      } else if (paymentResult.paymentIntent && paymentResult.paymentIntent.status === 'succeeded') {
        onSuccessfulPayment();
      } else {
        onPaymentError('Unexpected outcome from payment process. Please try again.');
      }
    } catch (error) {
      onPaymentError(error instanceof Error ? error.message : 'An unexpected error occurred.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="flex flex-col space-y-4">
        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
          Email Address
        </label>
        <input
          id="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="p-2 border rounded-md w-full"
          placeholder="Enter your email"
        />
      </div>

      <div className="flex items-center space-x-4">
        <label className="inline-flex items-center">
          <input
            type="radio"
            value="card"
            checked={paymentMethod === 'card'}
            onChange={() => setPaymentMethod('card')}
            className="form-radio"
          />
          <span className="ml-2">Credit Card</span>
        </label>
        <label className="inline-flex items-center">
          <input
            type="radio"
            value="alipay"
            checked={paymentMethod === 'alipay'}
            onChange={() => setPaymentMethod('alipay')}
            className="form-radio"
          />
          <span className="ml-2">Alipay</span>
        </label>
      </div>

      {paymentMethod === 'card' && <CardElement className="p-2 border rounded-md" />}

      <button
        type="submit"
        disabled={!stripe || isLoading}
        className="w-full py-2 px-4 bg-indigo-600 text-white rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
      >
        {isLoading ? 'Processing...' : 'Pay'}
      </button>

      {isLoading && <p className="text-center text-gray-600 dark:text-gray-400">Processing payment...</p>}
    </form>
  );
};

export default PaymentForm;
