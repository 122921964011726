﻿//Layout.tsx
import React, { ReactNode, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { Navbar, DarkThemeToggle, Flowbite } from 'flowbite-react';
import DashboardNavigation from './components/navigation/DashboardNavigation';
import Newsletter from './components/navigation/Newsletter';
import Footer from './components/navigation/Footer';
import { useLocation } from 'react-router-dom';
import { RoutePaths } from './routes/RoutePaths';
import NavDropdown from './components/navigation/NavDropdown';
import AdminNavigation from './admin/navbar/AdminNavigation';
import { Link } from 'react-router-dom';

interface LayoutProps {
    children?: ReactNode;
}


const Layout: React.FC<LayoutProps> = ({ children }) => {

    // Initialize theme from cookie or default to 'light'
    const [theme, setTheme] = useState(() => Cookies.get('theme') || 'light');

    // Update the cookie whenever the theme changes
    useEffect(() => {
        Cookies.set('theme', theme, { expires: 365 }); // Store for 1 year
    }, [theme]);

    // Apply the theme class to the body tag
    useEffect(() => {
        document.body.className = theme;
    }, [theme]);

    // Function to toggle theme
    const toggleTheme = () => {
        setTheme(prevTheme => prevTheme === 'dark' ? 'light' : 'dark');
    };

    const location = useLocation();
    const shouldShowDashboardNav = location.pathname.startsWith(RoutePaths.Dashboard);
    const shouldShowAdminNav = location.pathname.startsWith(RoutePaths.Admin);
    const shouldShowMainNav = location.pathname.endsWith(RoutePaths.Home);
    const shouldShowQueryNav = location.pathname.includes("power-dashboard");
    const shouldShowQueryNavPublic = location.pathname.includes("public-dashboard");
    const shouldShowQueryNavGuest = location.pathname.includes("guest-dashboard");

    return (

        <div className="app-layout flex flex-col min-h-screen dark:bg-black">
            <header>
                <Navbar>
                    <Link to={RoutePaths.Home}>
                        <span className="bg-transparent self-center whitespace-nowrap text-xl font-semibold dark:text-white">
                            Patalytica
                        </span>
                    </Link>
                    {shouldShowMainNav &&
                        <Navbar.Collapse>

                            {/* { <Link to={RoutePaths.Suite}>
                                <span className="self-center whitespace-nowrap text-xl text-gray-900 font-semibold dark:text-white">
                                    Patalytica suite
                                </span>
                            </Link>
                            <Link to={RoutePaths.Home}>
                                <span className="self-center whitespace-nowrap text-xl text-gray-900 font-semibold dark:text-white">
                                    In-house
                                </span>
                            </Link> 
                            <Link to={RoutePaths.PricingPage}>
                                <span className="self-center whitespace-nowrap text-xl text-gray-900 font-semibold dark:text-white">
                                    Pricing & FAQ
                                </span>
                            </Link>} */}
                        </Navbar.Collapse>}

                    {shouldShowQueryNav &&
                        <Navbar.Collapse>
                            <Link to={RoutePaths.MyQueries}>
                                <span className="self-center whitespace-nowrap text-xl text-gray-900 font-semibold dark:text-white">
                                    My Queries
                                </span>
                            </Link>
                            <Link to={RoutePaths.Categories}>
                                <span className="self-center whitespace-nowrap text-xl text-gray-900 font-semibold dark:text-white">
                                    Categories
                                </span>
                            </Link>
                        </Navbar.Collapse>
                    }

                    {shouldShowQueryNavPublic &&
                        <Navbar.Collapse>
                            <Link to={RoutePaths.MyQueries}>
                                <span className="self-center whitespace-nowrap text-xl text-gray-900 font-semibold dark:text-white">
                                    My Queries
                                </span>
                            </Link>
                            <Link to={RoutePaths.Categories}>
                                <span className="self-center whitespace-nowrap text-xl text-gray-900 font-semibold dark:text-white">
                                    Categories
                                </span>
                            </Link>
                        </Navbar.Collapse>
                    }

                    {shouldShowQueryNavGuest &&
                        <Navbar.Collapse>
                            <Link to={RoutePaths.MyQueries}>
                                <span className="self-center whitespace-nowrap text-xl text-gray-900 font-semibold dark:text-white">
                                    My Queries
                                </span>
                            </Link>
                            <Link to={RoutePaths.Categories}>
                                <span className="self-center whitespace-nowrap text-xl text-gray-900 font-semibold dark:text-white">
                                    Categories
                                </span>
                            </Link>
                        </Navbar.Collapse>
                    }
                    <Flowbite>
                        <DarkThemeToggle onClick={toggleTheme} />
                    </Flowbite>
                    <NavDropdown />
                </Navbar>
                {shouldShowDashboardNav && <DashboardNavigation />}
                {shouldShowAdminNav && <AdminNavigation />}
            </header>

            <main className="flex-grow">
                {children}
            </main>
            {shouldShowMainNav &&
                <Newsletter />
            }
            <Footer />
        </div>
    );
};

export default Layout;