// src/routes/routes.ts
import React from "react";
import HomePage from '../pages/HomePage'; 
import InvoiceGenerator from '../pages/InvoiceGenerator';
import Suite from '../pages/Suite';
import Login from '../components/Login';        
import ForgotPassword from '../components/ForgotPassword';        
import Register from '../components/Register';        
import Dashboard from '../components/Dashboard'; 
import Settings from '../components/dashboard/accountsettings/Settings';
import Billing from '../components/dashboard/billing/Billing';
import MyQueries from '../components/dashboard/mydownloads/MyQueries';
import Support from '../components/dashboard/support/Support';
import Team from '../components/dashboard/team/Team'; 
import RegistrationReceived from '../components/RegistrationReceived';
import ResetPasswordEmailSent from '../components/ResetPasswordEmailSent';
import AccountConfirm from '../components/AccountConfirm';
import AccountReset from '../components/AccountReset';
import { RoutePaths } from './RoutePaths';
import AdminPage from '../admin/pages/AdminPage';
import AdminDashboard from '../admin/pages/Dashboard';
import AdminRoles from '../admin/pages/Roles';
import AdminSubscriptionTiers from '../admin/pages/SubscriptionTiers';
import AdminRole from '../admin/pages/Role';
import AdminRoleEdit from '../admin/pages/RoleEdit';
import AdminSubscriptionTierEdit from '../admin/pages/SubscriptionTierEdit';
import AdminRoleUserAssignments from '../admin/pages/AdminRoleUserAssignments';
import ChangeLog from "../pages/ChangeLog";
import CheckoutPage from "../components/CheckoutPage";
import BlogPost from "../blog/pages/BlogPost";
import ListOfSameCategoryBlogPosts from "../blog/pages/ListOfSameCategoryBlogPosts";
import PricingPage from "../pages/PricingPage";
import DisplayData from "../business/pages/DisplayData";
import PowerDashboard from "../business/pages/PowerDashboard";
import PublicDashboard from "../business/pages/PublicDashboard";
import GuestDashboard from "../business/pages/GuestDashboard";
import DynamicDashboard from "../business/pages/DynamicDashboard";
import Categories from "../components/dashboard/categories/Categories";
import Category from "../components/dashboard/categories/Category";
import CategoryEdit from "../components/dashboard/categories/CategoryEdit";
import QueryCategoryAssignments from "../components/dashboard/querycategoryassignments/QueryCategoryAssignments";
import FamilyTreeSearchIframe from "../pages/FamilyTreeSearchIframe";
import EpoCalendar from "../suite_features/epo_calendar/components/EpoCalendar";

interface RouteType {
    path: RoutePaths ;
    component: React.ComponentType;
    protected?: boolean;
}

const routes: RouteType[] = [
    { path: RoutePaths.Home, component: HomePage, protected: false },
    { path: RoutePaths.Suite, component: Suite, protected: false },
    { path: RoutePaths.InvoiceGenerator, component: InvoiceGenerator, protected: false },
    { path: RoutePaths.Login, component: Login, protected: false },
    { path: RoutePaths.ForgotPassword, component: ForgotPassword, protected: false },
    { path: RoutePaths.Register, component: Register, protected: false },
    { path: RoutePaths.Dashboard, component: Dashboard, protected: true },
    { path: RoutePaths.Settings, component: Settings, protected: true },
    { path: RoutePaths.Billing, component: Billing, protected: true },
    { path: RoutePaths.MyQueries, component: MyQueries, protected: true },
    { path: RoutePaths.Categories, component: Categories, protected: true },
    { path: RoutePaths.Support, component: Support, protected: true },
    { path: RoutePaths.Team, component: Team, protected: true },
    { path: RoutePaths.RegistrationReceived, component: RegistrationReceived, protected: false },
    { path: RoutePaths.ResetPasswordEmailSent, component: ResetPasswordEmailSent, protected: false },
    { path: RoutePaths.AccountConfirm, component: AccountConfirm, protected: false },
    { path: RoutePaths.AccountReset, component: AccountReset, protected: true },
    { path: RoutePaths.Admin, component: AdminPage, protected: true },
    { path: RoutePaths.AdminDashboard, component: AdminDashboard, protected: true },
    { path: RoutePaths.AdminRoles, component: AdminRoles, protected: true },
    { path: RoutePaths.AdminSubscriptionTiers, component: AdminSubscriptionTiers, protected: true },
    { path: RoutePaths.AdminRole, component: AdminRole, protected: true },
    { path: RoutePaths.AdminRoleEdit, component: AdminRoleEdit, protected: true },
    { path: RoutePaths.AdminSubscriptionTierEdit, component: AdminSubscriptionTierEdit, protected: true },
    { path: RoutePaths.AdminRoleUserAssignments, component: AdminRoleUserAssignments, protected: true},
    { path: RoutePaths.ChangeLog, component: ChangeLog, protected: false},
    { path: RoutePaths.Checkout, component: CheckoutPage, protected: false},
    { path: RoutePaths.BlogPost, component: BlogPost, protected: false},
    { path: RoutePaths.PricingPage, component: PricingPage, protected: false},
    { path: RoutePaths.ListOfSameCategoryBlogPosts, component: ListOfSameCategoryBlogPosts, protected: false},
    { path: RoutePaths.DisplayEpRegister, component: DisplayData, protected: false},
    { path: RoutePaths.PowerDashboard, component: PowerDashboard, protected: false},
    { path: RoutePaths.DynamicDashboard, component: DynamicDashboard, protected: false},
    { path: RoutePaths.PublicDashboard, component: PublicDashboard, protected: false},
    { path: RoutePaths.GuestDashboard, component: GuestDashboard, protected: false},
    { path: RoutePaths.Category, component: Category, protected: true},
    { path: RoutePaths.CategoryEdit, component: CategoryEdit, protected: true},
    { path: RoutePaths.QueryCategoryAssignments, component: QueryCategoryAssignments, protected: true},
    { path: RoutePaths.FamilyTreeSearchIframe, component: FamilyTreeSearchIframe, protected: false},   
    { path: RoutePaths.EpoCalendarIframe, component: EpoCalendar, protected: false} 
];

export default routes;
